import React from 'react';
import PageHeader from '../components/PageHeader';
import ProfileImage from '../images/profile-pic.png';
import Button from '../components/Button';
import './About.css';

const About = () => {

  return (
    <>
    <section id='about'>
        <div className='content'>
          <img className='profilePicture' src={ProfileImage} alt='Troy Halliday' />
          <div className='aboutMe'>
            <h2 className='sectionTitle'>About Me</h2>
            <p>
              I’m a Product Design Leader with over 20 years of experience delivering user-focused, innovative designs that drive business growth. Throughout my career, I’ve excelled in leadership roles, guiding cross-functional teams from ideation to launch while ensuring products align seamlessly with user needs, business goals, and technical capabilities.
            </p>
            <h3>My design philosophy</h3>
            <h4>User-centric and business-focused:</h4>
            <p>
              I blend a deep understanding of user needs with a keen eye for business opportunities. By applying design thinking and leveraging user research, I create intuitive products that solve real problems and unlock growth potential.
            </p>
            <h4>Innovation and creativity:</h4>
            <p>
            I’m passionate about emerging trends and technologies, including AR and VR, and constantly explore ways to incorporate them into impactful, forward-thinking designs.
            </p>
            <h4>Leadership and collaboration:</h4>
            <p>
            I thrive on building inclusive, collaborative environments where teams feel inspired and empowered. My leadership approach emphasizes mentorship, empathy, and fostering creativity, enabling teams to consistently deliver exceptional results.
            </p>
            <h4>Strategic and Analytical:</h4>
            <p>
            I excel at turning complex challenges into simple, elegant solutions through strategic thinking and data-driven design. My ability to rapidly create proof-of-concept designs and prototypes allows me to identify and seize growth opportunities effectively.
            </p>
            <h3>Career Highlights</h3>
            <h4>Driving Business Growth:</h4>
            <p>
            Spearheaded the development of innovative features and new products that drove significant business growth. Rapidly created prototypes and proof-of-concepts, turning strategic visions into successful market opportunities.
            </p>
            <h4>Transformational leadership:</h4>
            <p>
            Transformed design teams into high-performing units by fostering a culture of collaboration, continuous learning, and creativity.
            </p>
            <h4>Diverse experience:</h4>
            <p>
            Built a diverse skill set, starting in branding and print design before moving into freelancing and web-based product development, fueling a passion for creating impactful designs.
            </p>
            <h4>Award-Winning Designs:</h4>
            <p>
            Received industry recognition for excellence in design and user experience throughout my career including 6 industry awards for wi-Q Solutions.
            </p>


          </div>
        </div>
      </section>
      <PageHeader
        id='contact'
        section={true}
        title='Let’s connect'
        backgroundText='Contact Me'
        showBreadcrumb={false}
      />
      <section className='contactDetails'>
        <p>
        I’m excited to bring my experience and passion for design to a forward-thinking organization. If you’re seeking an experienced product design leader with vision, creativity, and strategic insight, let’s connect and explore how we can achieve great things together.
        </p>
          <Button type='link' link='mailto:troy.halliday@gmail.com' startIcon='emailSolid'>troy.halliday@gmail.com</Button>
          <Button type='link' link='https://www.linkedin.com/in/troyhalliday/' startIcon='linkedInSolid'>/troyhalliday</Button>
      </section>      
    </>

  );
};

export default About;