import React from 'react';
import { useCookies } from 'react-cookie';
import Button from '../components/Button';

import './CookieConsent.css';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
  const denyCookieConsent = () => {
    setCookie("cookieConsent", 'deny', { path: "/" });
  };

  return (
    <div className="cookieConsent">
      <div className='container'>
        <p>
          We use cookies to enhance your user experience. By using our website,
          you agree to our use of cookies.{" "}
          <a href={"/cookies-policy"}>Learn more.</a>
        </p>
        <Button 
          type='primaryOutline'
          size='default'
          onClick={giveCookieConsent}
          >Accept</Button>
        <Button 
          type='primaryOutline'
          size='default'
          onClick={denyCookieConsent}
        >Decline</Button>
      </div>
    </div>
  );
};

export default CookieConsent;
