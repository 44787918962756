import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import About from './pages/About';
import AStock from './pages/aStock';
import AsiteBranding from './pages/asiteBranding';
import AsiteFieldApp from './pages/asiteFieldApp';
import AsitePlaybook from './pages/AsitePlaybook';
import AsiteMarketplace from './pages/asiteMarketplace';  
import BMWHeadunit from './pages/BMWHeadunit';
import CategoryPage from './pages/categoryPage';
import Footer from './components/Footer';
import GunsForSale from './pages/GunsForSale';
import Header from './components/Header';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiesPolicy from './pages/Cookies';
import IllegalAliensBranding from './pages/illegalAlienBranding';
import IllegalAliensCharacters from './pages/illegalAliensCharacters';
import IllegalAliensIllustrations from './pages/illegalAliensIllustrations';
import Jobs365 from './pages/Jobs365';
import Menu from './components/Menu';
import Repo2D3D from './pages/3DRepo2D3D';
import RepoClash from './pages/3DRepoClash';
import RepoEvo from './pages/3DRepoEvo';
import WiqBranding from './pages/WiqBranding';
import WiqProductDesign from './pages/wiqProductDesign';
import NotFound from './pages/NotFound'; 
import portfolioData from './data/portfolioData';
import { useCookies } from 'react-cookie';
import CookieConsent from './components/CookieConsent';
import { LocationProvider } from './utils/locationContext';

import useIsTouchDevice from './components/useIsTouchDevice';
import './App.css';

// GA4 Listener Component for Tracking Page Views
const GAListener = () => {
  const location = useLocation(); // Get current route

  useEffect(() => {
    if (window.gtag) {
      // Send a pageview to GA4 when the route changes
      window.gtag('config', 'G-7X8NMSMXM8', {
        page_path: location.pathname + location.search
      });
       console.log('GA4 pageview:', location.pathname + location.search);
    }
  }, [location]);

  return null; // This component doesn't render anything
};

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const pageRef = useRef(null);
  const isTouchDevice = useIsTouchDevice();
  const [cookies] = useCookies(["cookieConsent"]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Scroll event handling
  useEffect(() => {
    const handleScroll = () => {
      if (pageRef.current) {
        setIsScrolled(pageRef.current.scrollTop > 150);
      }
    };

    const pageElement = pageRef.current;
    if (pageElement) {
      pageElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (pageElement) {
        pageElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const categories = [...new Set(portfolioData.flatMap(item => item.categories))].sort();

  return (
    <Router>
      <LocationProvider> 
        {/* GA Listener is now inside the Router */}
        {cookies.cookieConsent && <GAListener />}
        <div className={`App ${menuOpen ? 'menu-open' : ''} ${isTouchDevice ? 'touch-device' : ''}`}>
          <Menu toggleMenu={toggleMenu} categories={categories} />
          <div className="page" ref={pageRef}>
            <Header toggleMenu={toggleMenu} menuOpen={menuOpen} isScrolled={isScrolled} pageRef={pageRef} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/3d-repo-design-system" element={<RepoEvo />} />
              <Route path="/3d-repo" element={<Repo2D3D />} />
              <Route path="/3d-repo-clash-detection" element={<RepoClash />} />
              <Route path="/about" element={<About />} />
              <Route path="/asite-astock" element={<AStock />} />
              <Route path="/asite-branding" element={<AsiteBranding />} />
              <Route path="/asite-field-app" element={<AsiteFieldApp />} />
              <Route path="/asite-marketplace" element={<AsiteMarketplace />} />
              <Route path="/asite-playbook" element={<AsitePlaybook />} />
              <Route path="/bmw-android-headunit" element={<BMWHeadunit />} />
              <Route path="/guns-for-sale" element={<GunsForSale />} />
              <Route path="/illegal-aliens-branding" element={<IllegalAliensBranding />} />
              <Route path="/illegal-aliens-characters" element={<IllegalAliensCharacters />} />
              <Route path="/illegal-aliens-illustrations" element={<IllegalAliensIllustrations />} />
              <Route path="/jobs-365" element={<Jobs365 />} />
              <Route path="/wiq-branding" element={<WiqBranding />} />
              <Route path="/wiq-product-design" element={<WiqProductDesign />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />
              {categories.map(category => {
                const formattedCategory = category.replace(/\s+/g, '-'); // Replace spaces with hyphens
                return (
                  <Route key={formattedCategory} path={`/${formattedCategory}`} element={<CategoryPage categoryName={category} />} />
                );
              })}
              <Route path="*" element={<NotFound />} />
            </Routes>
            { cookies.cookieConsent !== 'deny' && !cookies.cookieConsent  && <CookieConsent />}
            <Footer />
          </div>
        </div>
      </LocationProvider>
    </Router>
  );
}

export default App;
