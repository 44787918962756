import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import PortfolioSection from '../components/PortfolioSection';
import StillInterested from '../components/StillInterested';
import Button from '../components/Button';

import './Home.css';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Adding a delay to ensure the DOM is fully rendered
    }
  }, [location]);

  return (
    <>
      <PageHeader 
        title='Product Design Leader'
        backgroundText='Welcome'
        showBreadcrumb={false}
      />

      <section className='introduction'>
        <div className='introductionContent'>
          <p>
          Hi, I’m Troy Halliday, a seasoned product design leader with over 20 years of experience in crafting innovative, user-centered designs. Whether leading teams or driving individual projects, I’m passionate about creating designs that are functional, beautiful, and accessible. I’ve worked with startups and global organizations alike, delivering solutions that solve real problems, drive business growth, and delight users. I’m always excited about new challenges and opportunities to create impactful designs.
          </p>
        </div>
      </section>

      <PortfolioSection />
      <StillInterested hasButton={true} />

      <PageHeader
        id='contact'
        section={true}
        title='Why not get in touch?'
        backgroundText='Contact Me'
        showBreadcrumb={false}
      />
      <section className='contactDetails'>
        <Button type='link' link='mailto:troy.halliday@gmail.com' startIcon='emailSolid'>troy.halliday@gmail.com</Button>
        <Button type='link' link='https://www.linkedin.com/in/troyhalliday/' startIcon='linkedInSolid'>/troyhalliday</Button>
      </section>
    </>
  );
};

export default Home;
